<template>
  <div class="userIndexBox">
    <div class="userIndexHeaderBg">
      <img v-if="banner == null" src="@/assets/image/bg/pc_bg.png" alt="" />
      <img v-else :src="banner" alt="" />
      <el-upload
        accept="image/png,image/jpg,image/jpeg"
        class="upload-demo"
        :action="$ROOT_DIR + '/comms/Banner/personalHomepageNew?token=' + token"
        :on-success="handleAvatarSuccess"
      >
        <div
          class="changeBck"
          v-if="userInfo.uid == $route.query.uid && isLogin"
        >
          <img src="../../assets/image/user/edit.png" alt="" />
          <p>更改背景</p>
        </div>
      </el-upload>
    </div>
    <div class="userInfoBox">
      <div class="userInfo">
        <div class="userAvatarBox">
          <img :src="info.avatar" alt="" />
        </div>
        <p class="mt20 f0404 f_b f24 t_c">{{ info.username }}</p>
<!--        <div v-if="$route.query.uid && userInfo.uid != $route.query.uid">-->
<!--          <div class="btn" @click="attention()" v-if="focusStatus">-->
<!--            <img src="../../assets/image/user/jia.png" alt="" />-->
<!--            <p>关注</p>-->
<!--          </div>-->
<!--          <div-->
<!--            class="btn"-->
<!--            style="background: #dddddd; border: 2px solid #dddddd"-->
<!--            v-else-->
<!--          >-->
<!--            <img src="../../assets/image/user/qx.png" alt="" />-->
<!--            <p>已关注</p>-->
<!--          </div>-->
<!--        </div>-->
        <p class="f16 c_66 t_c mt20 w700 m_c">{{ info.about }}</p>
        <div class="dfc mt20">
          <div class="userMoreItem">
            <p class="c_33 f_b f20">{{ info.works }}</p>
            <p class="f14 c_66">作品</p>
          </div>
          <div class="userMoreItem">
            <p class="c_33 f_b f20">{{ info.focusOtherNum }}</p>
            <p class="f14 c_66">关注</p>
          </div>
          <div class="userMoreItem">
            <p class="c_33 f_b f20">{{ info.fans }}</p>
            <p class="f14 c_66">粉丝</p>
          </div>
          <div class="userMoreItem">
            <p class="c_33 f_b f20">{{ info.work }}</p>
            <p class="f14 c_66">所属行业</p>
          </div>
          <div class="userMoreItem">
            <p class="c_33 f_b f20">{{ info.joinType }}</p>
            <p class="f14 c_66">签约类型</p>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="userTabBox">-->
<!--      <div-->
<!--        v-if="courseTotal > 0"-->
<!--        class="userTabItemBox"-->
<!--        :class="{ active: type == 6 }"-->
<!--        @click="changeTab(6)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">课程</span>-->
<!--          <span>({{ courseTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--        v-if="suTotal > 0"-->
<!--        class="userTabItemBox"-->
<!--        :class="{ active: type == 561 }"-->
<!--        @click="changeTab(561)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">SU模型</span>-->
<!--          <span>({{ suTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--          v-if="maxTotal > 0"-->
<!--          class="userTabItemBox"-->
<!--          :class="{ active: type == 1393 }"-->
<!--          @click="changeTab(1393)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">3D模型</span>-->
<!--          <span>({{ maxTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--          v-if="schemeTextTotal > 0"-->
<!--          class="userTabItemBox"-->
<!--          :class="{ active: type == 1394 }"-->
<!--          @click="changeTab(1394)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">方案文本</span>-->
<!--          <span>({{ schemeTextTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--        v-if="materialTotal > 0"-->
<!--        class="userTabItemBox"-->
<!--        :class="{ active: type == 7 }"-->
<!--        @click="changeTab(7)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">资料库</span>-->
<!--          <span>({{ materialTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--        v-if="photoTotal > 0"-->
<!--        class="userTabItemBox"-->
<!--        :class="{ active: type == 2381 }"-->
<!--        @click="changeTab(2381)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">图库</span>-->
<!--          <span>({{ photoTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div-->
<!--        v-if="psTotal > 0"-->
<!--        class="userTabItemBox"-->
<!--        :class="{ active: type == 1196 }"-->
<!--        @click="changeTab(1196)"-->
<!--      >-->
<!--        <div class="userTabItem df_Yc f18 f_b">-->
<!--          <span class="mr8">PS素材</span>-->
<!--          <span>({{ psTotal }})</span>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="searchBox">
      <div class="searchBoxContent">
        <el-form :inline="true">
          <el-form-item>
            <div style="display: flex;" @mouseleave="searchInputShow = false">
              <transition name="el-zoom-in-center">
                <el-input v-model="key" v-if="searchInputShow || key" placeholder="搜索内容" @keyup.enter.native="getDataList"></el-input>
              </transition>
                <img @mouseenter="searchInputShow = true" class="searchBtn" src="../../assets/image/perSearch.png" @click="getDataList"/>
            </div>
<!--            <div @click="changeInputShow" v-else class="searchBtn1"></div>-->
          </el-form-item>
          <el-form-item  v-if="!(userInfo.uid == $route.query.uid && isLogin)">
            <div :title="!focusStatus?'点击取消关注':'点击加关注'" :class="[focusStatus?'collectBtn':'collectedBtn']" @click="!focusStatus ? cacelAttention() : attention()"></div>
          </el-form-item>
          <el-form-item>
            <div>
              <div v-if="!searchOrderShow"  class="orderBox1" @mouseleave="searchOrderShow = false;px = ''">
                <div  @mouseenter="searchOrderShow = true"><img src="../../assets/image/perOrder.png" class="orderBtn"></div>
              </div>
              <el-collapse-transition>
              <div v-if="searchOrderShow"  class="orderBox" @mouseleave="searchOrderShow = false;px = ''">
                <div><img src="../../assets/image/perOrder.png" class="orderBtn"></div>
                <div @click="setPx(1)" :class="{'activeColor':px === 1}">最新</div>
                <div @click="setPx(2)" :class="{'activeColor':px === 2}">最热</div>
              </div>
              </el-collapse-transition>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="userIndexFiltrateBox">
      <div style="display: flex;margin-bottom:18px">
        <img src="../../assets/image/icon/z.png" alt="" />
        <div class="mr60 classP">作品:</div>
        <div class="userMenuList">
          <div
              v-if="courseTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 6 }"
              @click="changeTab(6)"
          >
            {{ '课程' + "(" + courseTotal + ")" }}
          </div>
          <div
              v-if="suTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 561 }"
              @click="changeTab(561)"
          >
            {{ 'SU模型' + "(" + suTotal + ")" }}
          </div>
          <div
              v-if="maxTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 1393 }"
              @click="changeTab(1393)"
          >
            {{ '3D模型' + "(" + maxTotal + ")" }}
          </div>
          <div
              v-if="psTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 1196 }"
              @click="changeTab(1196)"
          >
            {{ 'PS素材' + "(" + psTotal + ")" }}
          </div>
          <div
              v-if="schemeTextTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 1394 }"
              @click="changeTab(1394)"
          >
            {{ '方案文本' + "(" + schemeTextTotal + ")" }}
          </div>
          <div
              v-if="materialTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 7 }"
              @click="changeTab(7)"
          >
            {{ '资料库' + "(" + materialTotal + ")" }}
          </div>
          <div
              v-if="chartletTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 2205 }"
              @click="changeTab(2205)"
          >
            {{ '贴图' + "(" + chartletTotal + ")" }}
          </div>
          <!-- <div
              v-if="photoTotal > 0"
              class="userMenuItem"
              :class="{ active: type == 2381 }"
              @click="changeTab(2381)"
          >
            {{ '图库' + "(" + photoTotal + ")" }}
          </div> -->
        </div>
      </div>
      <div style="display: flex">
        <img src="../../assets/image/icon/z.png" alt="" />
        <div class="mr60 classP">分类:</div>
<!--        <div class="userMenuList" :class="[oneLine ? 'oneLineStyle' : 'moreLineStyle']">-->
        <div class="userMenuList">
          <div
              class="userMenuItem"
              :class="{ active: activeIndex == 0 }"
              @click="checkMenu('', 0)"
          >
            全部
          </div>
          <div
              v-show="menuList.length > 0"
              class="userMenuItem"
              :class="{ active: activeIndex == index + 1 }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="checkMenu(item.id, index + 1)"
          >
            {{ item.title + "(" + item.num + ")" }}
          </div>
<!--          <i class="iconfont icon-xiala xiala" @click="changeLine" v-if="oneLine"></i>-->
<!--          <i class="iconfont icon-shouqi shouqi" @click="changeLine" v-if="!oneLine"></i>-->
        </div>
      </div>

    </div>
    <div class="mainContent">
      <div v-if="type == 6">
        <CourseList :courseList="list" :loading="loading"></CourseList>
      </div>
      <div v-if="type == 7">
        <MaterialList :materialList="list" :loading="loading"></MaterialList>
      </div>
      <div v-if="type == 561">
        <SuList :suList="list" :loading="loading"></SuList>
      </div>
      <div v-if="type == 1393">
        <MaxList :maxList="list" :loading="loading"></MaxList>
      </div>
      <div v-if="type == 1394">
        <SchemeTextList :schemeTextList="list" :loading="loading"></SchemeTextList>
      </div>
      <div v-if="type == 2381" class="mt30">
        <PhotoList
          ref="PhotoList"
          :photoList="list"
          :listStatus="listStatus"
          @nextPage="nextPage"
        ></PhotoList>
      </div>
			<div v-if="type == 2205" class="mt30">
				<chartlet-list
					ref="chartletList"
					:colNum = "tabsActiveNmae"
					:chartletObj="list"
					:loading="loading"
					:listStatus="listStatus"
					@nextPage="nextPage"
				></chartlet-list>
			</div>
				
      <div v-if="type == 1196" class="mt30">
        <PsList
          ref="PsList"
          :photoList="list"
          :listStatus="listStatus"
          @nextPage="nextPage"
        ></PsList>
      </div>
      <div class="t_c" style="margin-bottom: 0.3rem">
        <el-pagination
          v-show="type == 6 || type == 7 || type == 561 || type == 1393 || type == 1394"
          background
          layout="prev, pager, next"
          @current-change="setPage"
          :total="totalPage"
          :current-page="page"
          :page-size="page_size"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSchemeTextList,
  getSuModeList,
  getMaxModeList,
  getAuthorInfo,
  userGetBanner,
  focus,
  cancel,
  getFocus,
  getCateSum,
  getGatewayCateAndNum,
  getCourseNew,
  photoGetList,
	pictureGetList,
  getMaterialNew,
  getPsList,
} from "../../Api";
import { getToken } from "@/utils/auth";
import CourseList from "./components/CourseList";
import MaterialList from "@/components/MaterialListPage";
import PhotoList from "@/components/PhotoList";
import chartletList from "../../views/chartlet/componts/chartletList.vue"
import PsList from "@/components/PsList";
import SuList from "@/components/SuListPage";
import MaxList from "@/components/MaxListPage";
import SchemeTextList from "@/components/SchemeTextListPage";
import { mapGetters } from "vuex";

export default {
  name: "UserIndex",
  components: {
    CourseList,
    MaterialList,
    PhotoList,
    SuList,
    PsList,
    SchemeTextList,
    MaxList,
		chartletList
  },
  data() {
    return {
			tabsActiveNmae: "单张",
      searchInputShow:false,
      searchOrderShow:false,
      oneLine: true,
      child: "",
      key: '',
      px: '',
      listStatus: {
        isData: true,
        isNull: false,
      },
      page_size:10,
      totalPage: 0,
      menuList: [],
      loading: true,
      uid: "",
      banner: null,
      info: {
        avatar: "",
      },
      type: "",
      page: 1,
      courseTotal: 0,
      courseList: [],
      materialTotal: 0,
      materialList: [],
      photoTotal: 0,
      chartletTotal: 0,
      psTotal:0,
      suTotal: 0,
      photoList: [],
      psList:[],
      maxList:[],
      maxTotal:[],
      schemeTextList:[],
      schemeTextTotal:0,
      courseMenu: [],
      materialMenu: [],
      activeIndex: 0,
      total: 0,
      courseCId: "",
      materialCID: "",
      photoCID: "",
      focusStatus: true,
      images: "",
      token: getToken(),
      list: [],
    };
  },
  created() {
    //将参数设置到地址栏
    if (!this.$route.query.uid) {
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.uid = this.userInfo.uid;
      this.$router.push({ path: this.$route.path, query });
    }
    this.initData();
  },
  methods: {
    setPx(val){
      this.px = val
      this.getDataList()
    },
    changeInputShow(){
      this.searchInputShow = !this.searchInputShow
      if(!this.searchInputShow){
        this.searchInputShow = true
        return
      }
    },
    changeLine(){
      this.oneLine = !this.oneLine
      console.log(this.oneLine)
    },
    //下一页
    nextPage() {
      this.page += 1;
      this.getDataList(this.child);
    },
    //上传成功
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.banner = res.data.url;
      }
    },
    attention() {
      if (!this.isLogin) {
        this.$login();
      }
      focus({ token: getToken(), uid: this.uid }).then((res) => {
        if (res.data.message == "focusSuccess") {
          this.$message.success('成功添加关注')
          this.focusStatus = false;
        }
      });
    },
    cacelAttention(){
      if (!this.isLogin) {
        this.$login();
      }
      cancel({ token: getToken(), uid: this.uid }).then((res) => {
        if (res.data.message == "cancelSuccess") {
          this.$message.warning('成功取消关注')
          this.focusStatus = true;
        }
      });
    },
    initData() {
      this.uid = this.$route.query.uid
        ? this.$route.query.uid
        : this.userInfo.uid;
      getFocus({ token: getToken(), uid: this.uid }).then((res) => {
        if (res.data.message != "notFocus" && this.isLogin) {
          this.focusStatus = false;
        }
      });
      userGetBanner(this.uid).then((res) => {
        this.banner = res.data.url;
      });
      getAuthorInfo({ uid: this.uid }).then((res) => {
        this.info = res.data;
        if(this.info.type == 0){
          this.info.joinType = '暂未签约'
        } else if(this.info.type == 1){
          this.info.joinType = '个人签约'
        }else if(this.info.type == 2){
          this.info.joinType = '企业签约'
        }
      });
      getCateSum({ token: getToken(), sid: this.uid }).then((res) => {
        this.courseTotal = res.data[0];
        this.suTotal = res.data[1];
        this.materialTotal = res.data[2];
        this.photoTotal = res.data[3];
        this.chartletTotal = res.data[7];
        this.psTotal = res.data[4];
        this.maxTotal = res.data[5];
        this.schemeTextTotal = res.data[6];
        if (this.courseTotal > 0) {
          this.changeTab(6);
          return;
        }
        if (this.suTotal > 0) {
          this.changeTab(561);
          return;
        }
        if (this.materialTotal > 0) {
          this.changeTab(7);
          return;
        }
        if (this.photoTotal > 0) {
          this.changeTab(2381);
          return;
        }
        if (this.chartletTotal > 0) {
          this.changeTab(2205);
          return;
        }
        if (this.psTotal > 0) {
          this.changeTab(1196);
          return;
        }
        if (this.maxTotal > 0) {
          this.changeTab(1393);
          return;
        }
        if (this.schemeTextTotal > 0) {
          this.changeTab(1394);
          return;
        }
      });
    },
    //获取分类
    getGatewayCateAndNum(id) {
      getGatewayCateAndNum({ token: getToken(), sid: this.uid, id: id }).then(
        (res) => {
          this.menuList = res.data;
        }
      );
    },
    //分页
    setPage(val) {
      this.page = val;
      this.getDataList(this.child);
    },
    //切换类型
    changeTab(type) {
      this.activeIndex = 0;
      this.page = 1;
      this.type = type;
      this.list = [];
			this.listStatus = {
				isData: true,
				isNull: false,
			}
      //如果是图库切换菜单
      if (this.type == 2381) {
        this.$refs.PhotoList&&this.$refs.PhotoList.clear();
      }
      //如果是图库切换菜单
      if (this.type == 2205) {
        this.$refs.chartletList&&this.$refs.chartletList.clear();
      }
      //如果是图库切换菜单
      if (this.type == 1196) {
        this.$refs.PsList&&this.$refs.PsList.clear();
      }
      this.child = ''
      this.getDataList();
    },
    //  选择菜单
    checkMenu(id, index) {
      this.page = 1;
      this.activeIndex = index;
      this.list = [];
			this.listStatus = {
				isData: true,
				isNull: false,
			}
      //如果是图库切换菜单
      if (this.type == 2381) {
        this.$refs.PhotoList.clear();
      }
      //如果是图库切换菜单
      if (this.type == 2205) {
        this.$refs.chartletList.clear();
      }
      //如果是图库切换菜单
      if (this.type == 1196) {
        this.$refs.PsList.clear();
      }
      this.child = id;
      this.getDataList(id);
    },
    //获取列表数据
    getDataList() {
      const params = {
        child: this.child,
        author_uid: this.uid,
        page: this.page,
        per_page: 15,
        key: this.key,
        px: this.px
      };
      //获取课程
      if (this.type == 6) {
        this.loading = true;
        params.per_page = 10;
        getCourseNew(params).then((res) => {
          this.list = res.data.data;
          this.totalPage = res.data.total;
          this.page_size = params.per_page
          this.loading = false;
        });
      } else if (this.type == 561) {
        this.loading = true;
        params.per_page = 10;
        getSuModeList(params).then((res) => {
          this.list = res.data.data;
          this.totalPage = res.data.total;
          this.page_size = params.per_page
          this.loading = false;
        });
      } else if (this.type == 1393) {
        this.loading = true;
        params.per_page = 10;
        getMaxModeList(params).then((res) => {
          this.list = res.data.data;
          this.totalPage = res.data.total;
          this.page_size = params.per_page
          this.loading = false;
        });
      }else if (this.type == 7) {
        this.loading = true;
        params.per_page = 12;
        getMaterialNew(params).then((res) => {
          this.list = res.data.data;
          this.totalPage = res.data.total;
          this.page_size = params.per_page
          this.loading = false;
        });
      } else if (this.type == 2381) {
        photoGetList(params).then((res) => {
          this.list = res.data.data;
          if(res.data.data.length == 0){
            this.listStatus.isData = false
          }
          this.totalPage = res.data.total;
        });
      } else if (this.type == 2205) {
        pictureGetList(params).then((res) => {
          this.list = res.data;
          if(res.data.data.length == 0){
            this.listStatus.isData = false
          }
          this.totalPage = res.data.total;
          this.loading = false;
        });
      } else if (this.type == 1196) {
        getPsList(params).then((res) => {
          this.list = res.data.data;
          if(res.data.data.length == 0){
            this.listStatus.isData = false
          }
          this.totalPage = res.data.total;
        });
      }else if (this.type == 1393) {
        getMaxModeList(params).then((res) => {
          this.list = res.data.data;
          if(res.data.data.length == 0){
            this.listStatus.isData = false
          }
          this.totalPage = res.data.total;
        });
      }else if (this.type == 1394) {
        getSchemeTextList(params).then((res) => {
          this.list = res.data.data;
          if(res.data.data.length == 0){
            this.listStatus.isData = false
          }
          this.totalPage = res.data.total;
        });
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
  },
  watch: {
    isLogin: function () {
      this.initData();
    },
    type: {
      handler(val) {
        this.getGatewayCateAndNum(val);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.userMenuList::after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.oneLineStyle{
  height: 0.7rem !important;
  overflow: hidden !important;
}
.moreLineStyle{
  height: auto !important;
}
.userMenuList {
  margin-right: -0.3rem;
  margin-bottom: -0.4rem;
  flex: 1;
}

.userMenuItem.active {
  color: #f46600;
  background: #ffe7d5;
  border-radius: 0.2rem;
}

.userMenuItem {
  float: left;
  color: #333333;
  font-size: 0.14rem;
  margin-right: 0.3rem;
  margin-bottom: 0.4rem;
  padding: 0.05rem 0.1rem;
  cursor: pointer;
}

.userIndexFiltrateBox img {
  width: 0.1rem;
  height: 0.12rem;
  margin-right: 0.05rem;
  margin-top: 0.09rem;
}

.mainContent {
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}

.userIndexFiltrateBox {
  background: #ffffff;
  padding: 0.15rem 0.3rem 0.2rem;
  margin: 0.2rem 1.2rem 0.3rem;
  border-radius: 0.04rem;
  /*display: flex;*/
}

.userInfo {
  position: relative;
  /* height: 4.04rem; */
  top: -0.6rem;
}

.userTabItemBox {
  padding-bottom: 0.05rem;
  color: #666666;
  margin: 0 0.2rem;
  cursor: pointer;
}

.userTabItemBox.active {
  color: #f46600;
}

.userTabItemBox.active .line {
  width: 0.28rem;
  height: 0.03rem;
  background: #f46600;
  margin: 0.1rem auto 0;
  border-radius: 0.1rem;
}

.userTabBox {
  display: flex;
  justify-content: center;
  /*padding-top: 0.5rem;*/
  background: #ffffff;
}

.userInfoBox {
  background: #ffffff;
  height: 3.2rem;
}

.userMoreItem {
  cursor: pointer;
  text-align: center;
  width: 2.6rem;
  height: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.06rem;
  background: #f7f6fb;
  margin: 0 0.05rem;
}

.userAvatarBox {
  width: 1.2rem;
  height: 1.2rem;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
  /*background: coral;*/
}
.userAvatarBox img {
  width: 1.2rem;
  height: 1.2rem;
}

.userIndexBox {
  width: 100%;
  min-width: 1400px;
}

.userIndexHeaderBg {
  height: 2.7rem;
  overflow: hidden;
  position: relative;
}

.userIndexHeaderBg img {
  width: 100%;
}
.changeBck {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 1.2rem;
  background-color: #fff;
  /* width: 93px; */
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.changeBck img {
  width: 24px;
  height: 24px;
}
.changeBck p {
  /* width: 56px; */
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #4e6dc4;
  opacity: 1;
  cursor: pointer;
  margin-right: 8px;
}

.nameBox {
  display: flex;
}

.btn {
  /* margin-top: 0.3rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 30px;
  background: #ff9e58;
  border: 2px solid #fff4e0;
  opacity: 1;
  border-radius: 4px;
  margin: 0.2rem auto 0;
  cursor: pointer;
}

.btn img {
  width: 10px;
  height: 10px;
}

.btn p {
  margin-left: 5px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.classP {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  font-weight: bold;
  opacity: 1;
  padding: 0.05rem 0.1rem;
}
.xiala,.shouqi{
  position: relative;
  top:3px
}
.searchBox{
  position: relative;
  width: 100%;
  margin-bottom: 0.4rem;
}
.searchBoxContent{
  position:absolute;
  right:1.7rem;
  bottom:-0.5rem
}
.searchBoxContent  .el-form-item{
  /*width:0.5rem;*/
  /*height:0.5rem;*/
  /*cursor: pointer;*/
  margin-left: 0.2rem;
}
.searchBtn{
  cursor: pointer;
  z-index:1
}
.searchBtn1{
  width:50px;
  height:50px;
  cursor: pointer;
  background: url("../../assets/image/perSearch.png");
}
.collectBtn{
  width:50px;
  height:50px;
  cursor: pointer;
  background: url("../../assets/image/perCollect.png");
}
.collectedBtn{
  width:50px;
  height:50px;
  cursor: pointer;
  background: url("../../assets/image/perCollected.png");
}
.orderBtn{
  width:50px;
  height:50px;
  cursor: pointer;
  /*background: url("../../assets/image/perOrder.png");*/
}
.orderBox{
  position: absolute;
  width:50px;
  height: 150px;
  background: #E7E7E7 ;
  color: #999999;
  border-top-right-radius:25px;border-top-left-radius:25px;border-bottom-left-radius:25px;border-bottom-right-radius:25px;
}
.orderBox1{
  position: absolute;
  width:50px;
  height: 50px;
  border-top-right-radius:25px;border-top-left-radius:25px;border-bottom-left-radius:25px;border-bottom-right-radius:25px;
}
.orderBox div{
  height:50px;
  line-height: 50px;
  text-align: center;
  width:100%;
  cursor: pointer;
}
.activeColor{
  color: #333333 !important
}
/deep/.el-input__inner {
  background-color: #F46600 !important;
  position: relative;
  right: -0.5rem;
  height:50px;
  border-top-right-radius:25px;border-top-left-radius:25px;border-bottom-left-radius:25px;border-bottom-right-radius:25px;
  color:white
}
</style>