<!--
 * @FilePath: \zsw_new_edition\src\components\SuList.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-03-08 14:55:08
-->
<!--模型列表组件-->
<template>
	<div ref="box">
		<el-skeleton
			style="width: 100%"
			:loading="loading"
			animated
			:count="suList.length + 4"
		>
			<div slot="template">
				<el-skeleton-item
					variant="image"
					style="width: 3.2rem; height: 2.7rem; margin-bottom: 0.4rem"
				/>
			</div>
			<div v-if="list.length > 0 || isLoading">
				<div class="suList">
					<router-link
						:to="{ path: '/su/detail', query: { detailId: item.id } }"
						target="_blank"
						class="suItem"
						v-for="(item, index) in list"
						:key="index"
					>
						<div class="suItemImg">
							<img :src="item.img" alt="" />
						</div>
						<div class="suItemTitle">{{ item.title }}</div>
					</router-link>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
				</div>
				<p class="t_c mt20 loadImg" :class="{ load: isLoading && infinite }">
					<img src="@/assets/image/loading.gif" alt="" />
				</p>
				<p
					class="t_c mt20 loadImg"
					:class="{ load: !isLoading && !listStatus.isData && infinite }"
				>
					没有更多了...
				</p>
			</div>
			
			<div v-else>
				<el-empty description="没有内容哦"></el-empty>
			</div>
		</el-skeleton>
	</div>
</template>

<script>
export default {
  name: "SuList",
  props: {
		infinite: {
			type: Boolean,
			default: false, // 判断是否需要无限滚动
		},
    suList: {
      type: Array,
      default: () => [],	// 加载的数据
    },
    loading: {
      type: Boolean,
      default: false,	// 判断是否在加载中
    },
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		}
  },
	data() {
		return {
			list: [],
			isLoading: false,
		};
	},
  created() {},
	methods: {
		// 清除数据
		clear() {
			this.list = []
		},
		// 添加数据
    pushData() {
			if (this.infinite) {
				this.list = this.list.concat(this.suList);
				this.isLoading = false;
			} else{
				this.list = this.suList
			}
    },
		// 翻页
		loadmore() {
			if (this.listStatus.isNull || !this.listStatus.isData) return;
			this.$emit("nextPage");
		},
		// 判断滚动位置
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs.box.offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.isLoading && this.infinite) {
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.isLoading = true;
				this.loadmore();
			}
		},
	},
	mounted() {
		this.pushData();
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	watch: {
		suList() {
			this.pushData();
		}
	}
};
</script>

<style scoped>
	.el-empty{
		width:100%
	}
.loadImg {
  visibility: hidden;
	height: 0;
  opacity: 0;
  transition: 0.5s;
}
.loadImg.load {
	height: auto;
	margin:0 0 0.2rem;
  visibility: visible;
  opacity: 1;
}
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.suItemTitle {
  color: #22242E;
  font-size: 0.16rem;
  margin-top: 0.2rem;
  width: 2.5rem;
  font-weight: 600;
}

.suItem {
  width: 3.2rem;
  padding-right: 0.2rem;
  margin-bottom: 0.4rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.suItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}

.suItemImg {
  width: 3.2rem;
  height: 2.4rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.suItemImg img {
  width: 3.2rem;
  /* height: 2.4rem; */
}

::v-deep .suList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>