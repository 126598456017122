<!--课程列表组件-->
<template>
  <el-skeleton style="width:100%" :loading="loading" animated :count="10">
    <div slot="template">
      <el-skeleton-item variant="image" style="width: 3.2rem; height: 2.84rem;margin-bottom: 0.4rem"/>
    </div>
    <div class="courseList" v-if="courseList.length > 0">
      <router-link :to="{path:'/course/detail',query:{detailId:item.id}}" target="_blank" class="courseItem" v-for="(item,index) in courseList" :key="index">
        <div class="courseItemImg">
          <img :src="item.img" alt="">
        </div>
        <div class="courseItemTitleBox">
          <div class="courseItemTitle txt-over">{{item.title}}</div>
          <div class="df_Yc courseItemTitleIcon">
            <div class="courseTitle-tag">{{ item.originalAttr }}</div>
            <div class="titleIcon">
              <i class="iconfont">&#xe628;</i>
              <span>{{ item.view }}人</span>
            </div>
          </div>
        </div>
      </router-link>
      <div style="height: 0;width: 3.2rem"></div>
      <div style="height: 0;width: 3.2rem"></div>
      <div style="height: 0;width: 3.2rem"></div>
    </div>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>
  </el-skeleton>
</template>

<script>
export default {
  name: "CourseList",
  props:{
    courseList:{
      type:Array,
      default: () => []
    },
    loading:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{

    }
  },
}
</script>

<style scoped>
.titleIcon {
  font-size: 0.14rem;
  color: #999999;
}

.courseTitle-tag {
  background: #E94040;
  color: #FFFFFF;
  font-size: 0.12rem;
  padding: 0.01rem 0.05rem;
  border-radius: 0.02rem;
}

.courseItemTitleIcon {
  margin-top: 0.2rem;
  justify-content: space-between;
}

.courseItemTitleBox {
  padding: 0.1rem 0.1rem 0.2rem 0.1rem;
}

.courseItemTitle {
  width: 100%;
  color: #333333;
  font-size: 0.16rem;
}

.courseItem{
  /* width: 3.2rem; */
  width: 19%;
  margin-bottom: 0.4rem;
  background: #FFFFFF;
  border-radius: 0.08rem;
  overflow: hidden;
  box-shadow: 0px 0px 0.08rem rgba(153, 153, 153, 0.16);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

/* .courseItem:not(:nth-child(5n+0)){
  margin-right: 0.2rem;
} */
.courseItem:hover{
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
  -moz-box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
}

.courseItemImg {
  /* width: 3.2rem; */
  height: 1.92rem;
  overflow: hidden;
}
.courseItemImg img{
  width: 100%;
}

::v-deep .courseList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
