<template>
  <div class="box" ref="box" v-if="!listStatus.isNull">
		<waterfall :col="5" :data="imgList" :gutterWidth="0">
			<template>
				<div class="item" v-for="item in imgList" :key="item.id">
					<div @click="goLink(item.id)">
						<img v-if="item.img" :src="item.img" alt="" />
						<div class="info">
							<p>{{ item.title }}</p>
						</div>
					</div>
				</div>
			</template>
		</waterfall>
    <p class="t_c mt20 loadImg" :class="{ load: isLoading }">
      <img src="@/assets/image/loading.gif" alt="" />
    </p>
    <p
      class="t_c mt20 loadImg"
      :class="{ load: !isLoading && !listStatus.isData }"
    >
      没有更多了...
    </p>
  </div>
  <div v-else>
    <el-empty description="没有内容哦"></el-empty>
  </div>
</template>

<script>
import { getCollectView } from "../Api";
import { getToken } from "../utils/auth";
export default {
  name: "PhotoList",
  props: {
    photoList: {
      type: Array,
      default: () => [],
    },
    listStatus: {
      isData: true,
      isNull: false,
    },
  },
  components: {},
  data() {
    return {
      imgList: [],
      isLoading: true,
    };
  },
  computed: {
    disabled() {
      return this.listStatus.isData || this.listStatus.isNull;
    },
  },

  created() {},
  methods: {
    clear() {
      this.imgList = [];
    },
    loadmore() {
      if ((this.listStatus.isNull || !this.listStatus.isData) && !this.isLoading) return;
			this.isLoading = true;
      this.$emit("nextPage");
    },
    pushData() {
      this.imgList = this.imgList.concat(this.photoList);
      this.isLoading = false;
    },
    goLink(id) {
      const { href } = this.$router.resolve({
        path: "/ps/detail",
        query: { detailId: id },
      });
      window.open(href, "_blank");
    },
    download(id) {
      if (!getToken()) {
        this.$login();
        return;
      }
      window.location.href =
        this.$ROOT_DIR +
        "/web/IntentGallery/downloadPicNew?id=" +
        id +
        "&token" +
        getToken();
    },
    collect(info) {
      //判断是否收藏过
      if (info.isCollect != "no") {
        return;
      }
      getCollectView({ token: getToken(), fid: 273 }).then((res) => {
        if (res.data.error == "-1") {
          this.$login();
        }
        this.collectList = res.data;
        this.$collectPop({
          collectList: this.collectList,
          detailInfo: info,
          pid: info.id,
          token: getToken(),
          fid: 273,
        });
      });
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = this.$refs.box.offsetHeight;
      if (scrollTop + windowHeight >= offsetTop && !this.isLoading) {
        //到了这个就可以进行业务逻辑加载后台数据了
        if (this.listStatus.isNull || !this.listStatus.isData) return;
        
        this.loadmore();
      }
    },
  },
  mounted() {
    this.pushData();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    photoList() {
      this.pushData();
    },
  },
};
</script>
<style scoped>
/* .el-empty{
	width:100%
} */
.loadImg {
  visibility: hidden;
	height: 0;
  opacity: 0;
  transition: 0.5s;
}
.loadImg.load {
	height: auto;
	margin:0 0 0.2rem;
  visibility: visible;
  opacity: 1;
}
.vue-waterfall {
  width: 101%;
  /* padding-top: 0.2rem; */
}
.collect {
  width: 0.8rem;
  height: 0.33rem;
  z-index: 9;
  display: flex;
  align-items: center;
  background: #ffffff;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  border-radius: 0.04rem;
}

.item:hover .collect {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

.collect i {
  font-size: 0.18rem;
  color: #333333;
}

.collect p {
  font-size: 0.14rem;
  color: #333333;
  margin-right: 0.2rem;
}

.box {
  /* padding-bottom: 0.5rem; */
}

.item:hover {
  transform: translateY(-6px);
  /* box-shadow: 0 26px 40px -24px rgb(0 0 0 / 30%); */
}

.item {
  /* width: 3.2rem; */
  overflow: hidden;
  border-radius: 0.1rem;
  margin-bottom: 0.2rem;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: block;
  margin: 0 0.2rem 0.2rem 0;
}

.item img {
  /* border-radius: 0.1rem; */
}

.dow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  font-size: 0.22rem;
  padding: 0.07rem 0.15rem;
  background: #fcfcfc;
  border-radius: 0.04rem;
  transition: 0.5s;
}

.item:hover .dow {
  visibility: visible;
  opacity: 1;
}

.item img {
  width: 100%;
}

.collected {
  color: #f46600;
}
.info {
  height: 0.5rem;
  padding: 0 0.2rem;
  background: #ffffff;
  margin-top: -0.05rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.info p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 0.14rem;
  font-family: PingFang SC;
  /* font-weight: bold; */
  color: #040404;
  opacity: 1;
}
</style>